<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-2">
        <v-col>
          <h2>{{ surveyType.filter((e) => (e.value == targetSurveyType))[0].text }} 調査依頼編集・送信</h2>
        </v-col>
      </v-row>

      <!-- メール編集 -->
      <v-row class="">
        <v-col cols="2" sm="2" md="2" lg="2">
          <h3>メール編集</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="発信者"
            v-model="mailData.mailFrom"
            dense
            :readonly="true"
            :rules="[required]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(0, true, mailDestArray.tmpFromList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>発信者変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="宛先"
            v-model="mailData.mailTo"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(1, false, mailDestArray.tmpToList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>宛先変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="CC"
            v-model="mailData.mailCC"
            dense
            :readonly="true"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(2, false, mailDestArray.tmpCCList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>CC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9" sm="9" md="9" lg="9">
          <v-text-field
            label="BCC"
            v-model="mailData.mailBCC"
            dense
            :readonly="true"
            :clearable="false"
            :rules="[]"
            :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="showEmailAddress(3, false, mailDestArray.tmpBCCList)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>BCC変更</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-text-field
            label="件名"
            v-model="mailData.subject"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[required]"
            :maxLength="100"
            counter="100"
            :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="本文"
            v-model="mailData.body"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[required]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="署名"
            v-model="mailData.signature"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row v-for="n in (attachment.length + 1)" :key="n">
        <v-col cols="12" sm="12" md="12" lg="12">
          <!-- <div
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragover.prevent
            @drop.prevent="dropFile"
          > -->
          <div>
            <v-file-input
              :background-color="isEnter ? 'info' : 'transparent'"
              label="添付"
              v-model="attachment[n-1]"
              dense
              :readonly="false"
              :clearable="true"
              :rules="[]"
              :loading="loading"
              truncate-length="50"
              placeholder="ファイルを選択します。"
              persistent-placeholder
              @change="filterTmpAttachment"
            ></v-file-input>
          </div>
        </v-col>
      </v-row>

      <!-- 調査設定 -->
      <v-row class="mt-5">
        <v-col cols="2" sm="2" md="2" lg="2">
          <h3>調査設定</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" sm="auto" md="auto" lg="1">
          <YearPicker 
            label="実施年度"
            v-model="survey.info.targetYear"
            format="YYYY年"
            selectType="month"
            :readonly="false"
            :rules="[required]"
            :clearable="false"
            :loading="loading"
            suffix="度"></YearPicker>
        </v-col>
        <v-col class="d-flex align-center" cols="4" sm="4" md="4" lg="4">
          <DatePicker
            label="回答受付期間 開始"
            v-model="survey.destInfo.ansPeriodFrom"
            format="YYYY/MM/DD"
            selectType="date"
            :readonly="false"
            :rules="[required, checkStartEndDate(survey.destInfo.ansPeriodFrom, survey.destInfo.ansPeriodTo)]"
            :clearable="false"
            :loading="loading"
          ></DatePicker>
          ～
          <DatePicker
            label="回答受付期間 終了"
            v-model="survey.destInfo.ansPeriodTo"
            format="YYYY/MM/DD"
            selectType="date"
            :readonly="false"
            :rules="[required, checkStartEndDate(survey.destInfo.ansPeriodFrom, survey.destInfo.ansPeriodTo)]"
            :clearable="false"
            :loading="loading"
          ></DatePicker>
        </v-col>
      </v-row>
      <v-row v-if="targetSurveyType == 3 || targetSurveyType == 4">
        <v-col class="d-flex align-center" cols="4" sm="4" md="4" lg="4">
          <DatePicker
            label="調査対象期間 開始"
            v-model="survey.info.targetMonthFrom"
            format="YYYY/MM/DD"
            selectType="date"
            :readonly="false"
            :rules="[required, checkStartEndDate(survey.info.targetMonthFrom, survey.info.targetMonthTo)]"
            :clearable="false"
            :loading="loading"
          ></DatePicker>
          ～
          <DatePicker
            label="調査対象期間 終了"
            v-model="survey.info.targetMonthTo"
            format="YYYY/MM/DD"
            selectType="date"
            :readonly="false"
            :rules="[required, checkStartEndDate(survey.info.targetMonthFrom, survey.info.targetMonthTo)]"
            :clearable="false"
            :loading="loading"
          ></DatePicker>
        </v-col>
        <v-col v-if="targetSurveyType == 3" cols="auto" sm="auto" md="auto" lg="2">
          <DatePicker
            label="基礎情報年月"
            v-model="survey.info.asOfMonth"
            format="YYYY年MM月"
            selectType="month"
            :clearable="false"
            suffix="末現在"
            :rules="[required]"></DatePicker>
        </v-col>
      </v-row>

      <!-- 追加注意書き -->
      <v-row v-if="targetSurveyType == 3 || targetSurveyType == 4">
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-textarea
            label="調査に関する注意書き"
            v-model="survey.info.addNote"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[]"
            :maxLength="30000"
            :loading="loading"
            counter="30000"
            hint="回答画面上部に表示される注意書きを追加できます。"
            persistent-hint
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- 追加設問 -->
      <v-row class="mt-5">
        <v-col>
          <h3>その他の追加設問</h3>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="addQa()" :disabled="loading" ><v-icon :small="true">mdi-plus-circle</v-icon>設問追加</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headersQaList"
            :items="survey.qaList"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border send-table"
            height="auto"
          >
            <template v-slot:item="{ item, index }">
              <tr :key="item.memberNo">
                <td class="center">
                  {{index+1}}
                </td>
                <td class="left">
                  <v-text-field
                    v-model="item.question"
                    dense
                    :readonly="(item.sid != null)"
                    :rules="[required_ns]"
                    :maxLength="255"
                    :loading="loading"
                    counter="255"
                  ></v-text-field>
                </td>
                <td class="center">
                  <v-btn small class="secondary" :height="20" :disabled="loading || (item.sid != null)" @click="deleteQa(index)"> 削除 </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- 調査依頼先 -->
      <v-row class="mt-5">
        <v-col>
          <h3>調査依頼先</h3>
        </v-col>
        <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="show(survey.dest)" :disabled="loading" ><v-icon :small="true">mdi-border-color</v-icon>調査依頼先変更</v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-n7">
        <v-col v-if="!loading" cols="12" sm="12" md="12" lg="12">
          <input :disabled="survey.dest.length <= 0" type='checkbox' id="select-all-checkbox" v-model="selectAll" /><label for="select-all-checkbox">すべて選択</label>
        </v-col>
        <v-col v-else cols="12" sm="12" md="12" lg="12">
          <input :disabled="true" type='checkbox' :value="false" /><label>すべて選択</label>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="survey.dest"
            :loading="loading"
            :dense='true'
            :items-per-page="10"
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-footer='true'
            :fixed-header='true'
            disable-sort
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border send-table"
            :search="searchCondition.companyName"
            height="auto"
          >
            <template v-slot:item="{ item }">
              <tr :key="item.memberNo">
                <td class="center">
                  <input type='checkbox' v-model="survey.mailList" :value="item" />
                </td>
                <td class="center">
                  {{item.memberNo}}
                </td>
                <td class="left">
                  {{item.companyName}}
                </td>
                <td class="left">
                  {{item.officeName}}
                </td>
                <td class="left">
                  {{item.toList}}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- アクション -->
      <v-row>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="registData()" :disabled="loading" ><v-icon :small="true">mdi-send</v-icon>調査依頼メール送信</v-btn>
        </v-col>
        <v-col cols="6" sm="6" md="6" lg="6" class="text-center">
          <v-btn class="btn_font primary" width="200" v-on:click="cancel()" :disabled="loading" >キャンセル</v-btn>
        </v-col>
      </v-row>

    </v-container>

    <!-- modal -->
    <modal name="modal-dialog-from" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal-from">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>メールアドレス選択</h2>
          <v-icon x-large v-on:click="hideFrom()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectEmailAddress v-on:closeEvt="hideFrom" v-on:registEvt="updateEmailAddressList" :selectedList="modalSelectedList" :type="emailType" :solo="emailSolo" />
        </div>
      </div>
    </modal>

    <!-- modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" 
                                height="auto" width="80%" id="vm--modal">
      <div class="">
        <div class="d-flex modal-header mt-5 justify-space-between">
          <h2>事業所選択</h2>
          <v-icon x-large v-on:click="hide()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <SelectMember v-on:closeEvt="hide" v-on:registEvt="updateDest" :selectedList="modalSelectedList" :searchType="targetSurveyType" :targetDate="survey.info.targetYear"/>
        </div>
      </div>
    </modal>

  </v-form>
</template>

<script>
import Debug from '../Lib/Debug'
import dayjs from "dayjs";
import Message from "../Lib/Message";
import DatePicker from "./common/DatePicker.vue";
import YearPicker from "./common/YearPicker.vue";
import Mixin from "../mixins/const.js";

import SelectMember from './SelectMember.vue';
import SelectEmailAddress from './SelectEmailAddress.vue';

import MailTemplate from "../model/mailTemplate";
import FactFinding from "../model/factFinding";
import Electricity from "../model/electricity";
import Refrigerant from "../model/refrigerant";

export default {
  mixins: [Mixin],

  components: {
    DatePicker,
    YearPicker,
    SelectMember,
    SelectEmailAddress,
  },

  data: () => ({
    valid : true,
    loading: false,

    // ファイル ドラッグアンドドロップ
    isEnter: false,

    // メールアドレス選択
    emailType: 0,
    emailSolo: false,

    // 対象調査種別
    targetSurveyType: null,

    // 検索条件
    searchCondition: {
      companyName: "",
    },

    // テンプレートデータ
    mailData: {},
    templateData: {
      templateId: null,
      type: 0,
      mailFrom: '',
      mailTo: '',
      mailCC: '',
      mailBCC: '',
      subject: '',
      body: '',
      signature: '',
    },
    // メールアドレス一時リスト
    mailDestArray: {
      tmpFromList: [],
      tmpToList: [],
      tmpCCList: [],
      tmpBCCList: [],
    },

    // 添付一時ファイル(ドラッグアンドドロップ用)
    tmpDropFiles: [],

    // テーブルヘッダー
    headers: [
        { text: 'ﾒｰﾙ送信', align: 'center', sortable: true, value: 'check', width: "5%" },
        { text: '会員番号', align: 'center', sortable: true, value: 'memberNo', width: "5%" },
        { text: '会社名', align: 'center', sortable: true, value: 'companyName', width: "25%" },
        { text: '事業所名', align: 'center', sortable: true, value: 'officeName', width: "25%" },
        { text: '宛先', align: 'center', sortable: true, value: 'toList', width: "40%" },
    ],

    // 調査設定
    survey: {
      sid: null,

      info: {
        targetYear: '',       // 実施年度
        targetMonthFrom: '',  // 調査対象期間 開始
        targetMonthTo: '',    // 調査対象期間 終了
      },
      destInfo: {
        // 回答期間
        ansPeriodFrom: '',
        ansPeriodTo: '',
      },

      // 調査対象(会員番号)リスト
      dest: [],
      // メール送信対象(会員番号)リスト
      mailList: [],

      // 追加設問
      qaList: [],

    },

    // 添付ファイル
    attachment: [],

    // 追加設問ヘッダー
    headersQaList: [
        { text: 'No', align: 'center', sortable: true, value: 'qaNo', width: "10%" },
        { text: '設問', align: 'center', sortable: true, value: 'question', width: "80%" },
        { text: '', align: 'center', sortable: true, value: 'action', width: "10%" },
    ],

    // モーダル用リスト
    modalList: [],
    modalSelectedList: [],

    // 1つ前の実施年度
    oldTargetYear: '',

    // 既存調査データ有無
    existSurvey: false,

  }),

  // ライフサイクルフック
  created: function () {
    this.targetSurveyType = this.$store.getters.getTargetSurveyType;

    this.init();

    this.getData();
  },

  mounted: async function () {

  },

  methods: {
    init() {
      Debug.log('function[init]');
      this.loading = true;

      // 全調査共通
      // 実施年
      this.survey.info.targetYear = dayjs().startOf('year').format().substr(0, 7);  // 実施年を現在年の1月1日に設定

      switch(this.targetSurveyType) {
        case 1:
          // 月次報告データ(なし)
          break;
        case 4:
          // 冷媒調査
          this.survey.info.targetMonthFrom = '';
          this.survey.info.targetMonthTo = '';
          this.survey.info.addNote = null;
          break;
          
        case 2:
          // 実態調査
          break;

        case 3:
          // 電力調査
          this.survey.info.targetMonthFrom = '';
          this.survey.info.targetMonthTo = '';
          this.survey.info.asOfMonth = '';
          this.survey.info.addNote = null;
          break;
      }

      this.loading = false;
    },

    /** 表示更新 */
    async getData() {
      Debug.log('function[getData]');
      this.loading = true;

      try {
        const res = await MailTemplate.getMailTemplateDataForType(this.targetSurveyType);
        Debug.log(res.data);
        // レスポンス確認
        if(res.data.template.length > 0 && res.status == 200) {
          this.templateData = res.data.template[0];
          this.mailDestArray.tmpFromList = res.data.from;
          this.mailDestArray.tmpToList = res.data.to;
          this.mailDestArray.tmpCCList = res.data.cc;
          this.mailDestArray.tmpBCCList = res.data.bcc;
        }
        this.mailData = JSON.parse(JSON.stringify(this.templateData));
      } catch (error) {
        Message.err(error, "メールテンプレートを取得できませんでした");
      }
      this.loading = false;
    },

    // ファイル ドラッグアンドドロップ
    dragEnter() {
      Debug.log('function[dragEnter]');
      this.isEnter = false;
    },
    dragLeave() {
      Debug.log('function[dragLeave]');
      this.isEnter = false;
    },
    dropFile() {
      Debug.log('function[dropFile]');
      // ドロップされたファイルの取得
      this.tmpDropFiles = [...event.dataTransfer.files];
      Debug.log(this.tmpDropFiles);

      // 添付ファイルに追加
      for(let i=0; i < this.tmpDropFiles.length; i++) {
        this.attachment.push(this.tmpDropFiles[i]);
      }
      // 一時ファイルの初期化
      this.tmpDropFiles = [];

      this.isEnter = false;
    },
    filterTmpAttachment() {
      // リストからnullを削除
      Debug.log('function[filterTmpAttachment]');
      this.attachment = this.attachment.filter(v => !!v);
    },

    // メールアドレス更新
    updateEmailAddressList (type, innerSelectedList, tmpStr) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      switch(type) {
        case 0:
          // 発信者
          //this.mailData.mailFrom = (tmpStr === '') ? ((this.templateData.mailFrom == null || this.templateData.mailFrom == '') ? '' : this.templateData.mailFrom) : tmpStr;
          this.mailData.mailFrom = tmpStr;
          this.mailDestArray.tmpFromList = [innerSelectedList];
          Debug.log(this.mailDestArray.tmpFromList);
          break;

        case 1:
          // 宛先
          //this.mailData.mailTo = (tmpStr === '') ? this.templateData.mailTo : ((this.templateData.mailTo == null || this.templateData.mailTo == '') ? tmpStr : this.templateData.mailTo + ',' + tmpStr);
          this.mailData.mailTo = tmpStr;
          this.mailDestArray.tmpToList = innerSelectedList;
          break;

        case 2:
          // CC
          //this.mailData.mailCC = (tmpStr === '') ? this.templateData.mailCC : ((this.templateData.mailCC == null || this.templateData.mailCC == '') ? tmpStr : this.templateData.mailCC + ',' + tmpStr);
          this.mailData.mailCC = tmpStr;
          this.mailDestArray.tmpCCList = innerSelectedList;
          break;

        case 3:
          // BCC
          //this.mailData.mailBCC = (tmpStr === '') ? this.templateData.mailBCC : ((this.templateData.mailBCC == null || this.templateData.mailBCC == '') ? tmpStr : this.templateData.mailBCC + ',' + tmpStr);
          this.mailData.mailBCC = tmpStr;
          this.mailDestArray.tmpBCCList = innerSelectedList;
          break;
      }

      this.loading = false;
    },

    // 調査対象リスト更新
    updateDest (innerSelectedList) {
      Debug.log('function[updateEvt]');
      this.loading = true;

      // 編集画面での変更を反映する
      this.survey.dest = innerSelectedList;
      Debug.log2('this.survey.dest', this.survey.dest);

      // メール送信リストを退避
      const tmpMailList = this.survey.mailList;
      Debug.log2('tmpMailList', tmpMailList);
      // メール送信リストを初期化
      this.survey.mailList = [];
      // 退避したメール送信リストの事業所が、調査対象リストに存在すれば、メール送信リストに追加
      tmpMailList.forEach(function (d) {
        const tmpNewMailList = this.survey.dest.find(function(b){
                                  return b.memberNo == d.memberNo
                                });
        if(typeof tmpNewMailList !== "undefined" && tmpNewMailList !== null) {
          this.survey.mailList.push(tmpNewMailList);
        }
      }, this);

      this.loading = false;
    },

    // 設問追加
    addQa() {
      Debug.log('function[addQa]');

      this.survey.qaList.push({sid: null, question: ''});
    },
    // 設問削除
    deleteQa(index) {
      Debug.log('function[deleteQa]');
      
      this.survey.qaList.splice(index, 1);
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
      this.$router.back();
    },

    // 調査依頼送信
    async registData() {
      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (this.survey.dest.length <= 0) {
        alert("調査依頼先を設定してください。");
        return;
      }

      if (!confirm("調査依頼を送信します。よろしいですか？")) return;
      this.loading = true;
      try {
        let res = null;
        switch(this.targetSurveyType) {
          case 1:
            // 月次報告データ
            Debug.log('月次報告データ');
            res = {data: ''};
            break;
            
          case 2:
            // 実態調査
            Debug.log('実態調査');
            res = await FactFinding.setFactFindingData(this.mailData, this.mailDestArray, this.survey, this.attachment, 'new');
            break;

          case 3:
            // 電力調査
            Debug.log('電力調査');
            res = await Electricity.setElectricityData(this.mailData, this.mailDestArray, this.survey, this.attachment, 'new');
            break;

          case 4:
            // 冷媒調査
            Debug.log('冷媒調査');
            res = await Refrigerant.setRefrigerantData(this.mailData, this.mailDestArray, this.survey, this.attachment, 'new');
            break;
        }

        // サーバーサイドのバリデーション 
        if(res.status == 200) {
          const validationMsg = res.data;
          if (validationMsg) {
            let message = "";
            if (Array.isArray(validationMsg)) {
              validationMsg.forEach(m => message += m + '\n')
            } else {
              message = validationMsg;
            }
            alert(message);

            this.loading = false;
            return;
          } else {
            // 送信成功
            alert("送信しました。");
            this.$router.back();
          }
        }

      } catch (error) {
        Message.err(error, "送信できませんでした");
      }

      this.loading = false;
    },

    // 調査データ取得
    async getSurvey() {
      this.loading = true;
      try {
        let res = null;
        switch(this.targetSurveyType) {
          case 1:
            // 月次報告データ
            Debug.log('月次報告データ');
            res = {data: ''};
            break;
            
          case 2:
            // 実態調査
            Debug.log('実態調査');
            res = await FactFinding.getExistSurvey(this.survey.info.targetYear);
            break;

          case 3:
            // 電力調査
            Debug.log('電力調査');
            res = await Electricity.getExistSurvey(this.survey.info.targetYear);
            break;

          case 4:
            // 冷媒調査
            Debug.log('冷媒調査');
            res = await Refrigerant.getExistSurvey(this.survey.info.targetYear);
            break;
        }

        if(res.status == 200 && res.data) {
          Debug.log('既存調査データあり');
          this.existSurvey = true;
          
          // 調査データを設定
          this.survey.sid = res.data.info.sid;
          // 対象年度
          this.survey.info.targetYear = res.data.info.targetYear;

          switch(this.targetSurveyType) {
            case 1:
              // 月次報告データ
              break;
            case 4:
              // 冷媒調査
              this.survey.info.targetMonthFrom = res.data.info.targetMonthFrom;
              this.survey.info.targetMonthTo = res.data.info.targetMonthTo;
              this.survey.info.addNote = res.data.info.addNote;
              break;
              
            case 2:
              // 実態調査
              break;

            case 3:
              // 電力調査
              this.survey.info.targetMonthFrom = res.data.info.targetMonthFrom;
              this.survey.info.targetMonthTo = res.data.info.targetMonthTo;
              this.survey.info.asOfMonth = res.data.info.asOfMonth;
              this.survey.info.addNote = res.data.info.addNote;
              break;
          }

          // 追加設問
          this.survey.qaList = res.data.qaList;
        } else {
          this.existSurvey = false;
          // 調査データ初期値を設定
          this.survey.sid = null;

          switch(this.targetSurveyType) {
            case 1:
              // 月次報告データ
              break;
            case 4:
              // 冷媒調査
              this.survey.info.targetMonthFrom = '';
              this.survey.info.targetMonthTo = '';
              this.survey.info.addNote = null;
              break;
              
            case 2:
              // 実態調査
              break;

            case 3:
              // 電力調査
              this.survey.info.targetMonthFrom = '';
              this.survey.info.targetMonthTo = '';
              this.survey.info.asOfMonth = '';
              this.survey.info.addNote = null;
              break;
          }

          // 追加設問
          this.survey.qaList = [];
        }

      } catch (error) {
        Message.err(error, "実態調査一覧を取得できませんでした");
      }

      this.loading = false;
    },

    /** アドレス帳モーダル表示用 */
    showEmailAddress (type, solo, selectedList) {
      Debug.log('function[show]');

      this.emailType = type;
      this.emailSolo = solo;
      this.modalSelectedList = selectedList;
      this.$modal.show('modal-dialog-from');
    },
    hideFrom : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog-from');
    },
    /** モーダル表示用 */
    show (selectedList) {
      Debug.log('function[show]');

      this.modalSelectedList = selectedList;
      this.$modal.show('modal-dialog');
    },
    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');
    },
  },

  computed: {
    selectAll: {
      get: function () {
        Debug.log('selectAll get');
        return this.survey.dest ? this.survey.dest.length > 0 && this.survey.mailList.length == this.survey.dest.length : false;
      },
      set: function (sid) {
        let tmpSelectedList = [];
        if (sid) {
          // すべて選択の場合
          Debug.log('selectAll set');
          this.survey.dest.forEach(function (memberList) {
            tmpSelectedList.push(memberList);
          });
        }

        this.survey.mailList = tmpSelectedList;
      }
    }
  },

  watch: {
    // 'survey.info.targetYear': function(newValue, oldValue){
    //   if(oldValue != '' && this.oldTargetYear != oldValue ) {
    //     if (!confirm("調査依頼先はリセットされます。よろしいですか？")) {
    //       this.$nextTick(() => {
    //         this.oldTargetYear = newValue;
    //         this.survey.info.targetYear = oldValue;
    //         return;
    //       });
    //     } else {
    //       this.oldTargetYear = oldValue;
    //       this.survey.dest = [];
    //     }
    //   }
    // },
    'survey.info.targetYear': function(){
      // 調査データ取得
      this.getSurvey();
    },
  },
};
</script>

<style scoped>

.disablecolor {
  background: #bbbbbb;
}

.send-table >>> .v-data-table__wrapper {
  max-height: 500px;
}

</style>
