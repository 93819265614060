import Axios from "axios";
import token from "./setToken";

export default {

  async getData(email, attribute) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/Muser/" + email + ',' + attribute, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("ユーザデータを取得できません[getData]");
      throw error;
    }
  },

  async updateData(userData, mode) {

    let params = new URLSearchParams();
    params.append('userData', JSON.stringify(userData));
    params.append('mode', mode);

    try {
      const res = await Axios.post('/api/Muser', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      // エラーログを出力して、再スロー
      console.error("データを更新できません[setUserData]");
      throw error;
    }
  }, 

  async deleteData(email, userData) {
    try {
      // const res = await Axios.delete('/api/Muser/' + email, token.setTokenToHeader());
      const res = await Axios.delete('/api/Muser/' + email + ',' + userData.attribute, token.setTokenToHeader());
      return res;

    } catch (error) {
      // エラーログを出力して、再スロー
      console.error("データを削除できません[delUserData]");
      throw error;
    }
  }, 


  async getAssociationFeeUserList(idList, type) {
    let params = new URLSearchParams();
    // params.append('addressType', 3);      // 請求書
    params.append('associationIdList', JSON.stringify(idList));
    params.append('type', type);

    try {
      const res = await Axios.post('/api/MuserAdAddressList', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      // エラーログを出力して、再スロー
      console.error("データを取得できません[getAssociationFeeUserList]");
      throw error;
    }

  },


  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MuserList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("ユーザ一覧を取得できません[search]");
      throw error;
    }
  },

  async searchEmailAddress(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MuserEmailAddressList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("宛先ユーザ一覧を取得できません[search]");
      throw error;
    }
  },

};
