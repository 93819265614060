<template>
  <v-menu
    ref="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :left="leftMenu"
    v-model="menu"
    :disabled="readonly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formatValue"
        :label="label"
        :loading="loading"
        readonly
        :clearable="clearable"
        v-bind="attrs"
        v-on="on"
        @click:clear="clear"
        :rules="rules"
        dense
        :hide-details="hideDetails"
        :prefix="prefix"
        :suffix="suffix"
        :background-color="backgroundColor"
        :single-line="singleLine"
        :class="propClass"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="innerValue"
      locale="ja-jp"
      :day-format="(date) => new Date(date).getDate()"
      @click:year="saveYear"
      :readonly="readonly"
      :no-title="true"
      :type="selectType"
      :active-picker.sync="activePicker"
      :max="getMaxDate"
      min="1950-01-01"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    label: String,
    value: String,
    format: String,
    selectType: String,
    rules: Array,
    readonly: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    leftMenu: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    changeEvt: {
      type: Function,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    prefix: String,
    suffix: String,
    backgroundColor: String,
    singleLine: {
      type: Boolean,
      default: false,
    },
    propClass: String,
  },

  data() {
    return {
      innerValue: null,
      menu: false,
      activePicker: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        //let tmpVal;
        if(value != null && value != '') {
          //tmpVal = value.split('-');
          //this.innerValue = tmpVal[0];
          this.innerValue = value;
        } else {
          this.innerValue = value;
        }
        this.changeEvt();
      },
    },
    menu: {
      immediate: true,
      handler(value) {
        if (value) {
          // デートピッカーを開いた
          value && setTimeout(() => (this.activePicker = 'YEAR'));

          // TODO:スクロール位置がズレる(0になる)ので調整したい
          // this.$nextTick(() => {
            // let element = document.getElementsByClassName('v-date-picker-years');
            // let target = element.children;
            //let firstChildElement = element.childNodes;
            //console.log(this.$refs.picker);
          // });
          this.$emit("showPicker");
        }
      },
    },
  },

  methods: {
    clear() {
      this.innerValue = null;
      this.$emit("input", null);
    },

    save(date) {
      this.$refs.menu.save(date);

      // 親コンポーネント（のv-model）に値を渡す
      this.$emit("input", date);
    },

    saveYear(date) {
      this.$refs.menu.save(date+'-01')

      // 親コンポーネント（のv-model）に値を渡す
      this.$emit("input", date+'-01');

      this.menu = false
    },

  },

  computed: {
    formatValue: {
      get() {
        if (this.format) {
          return this.innerValue ? dayjs(this.innerValue).format(this.format) : "";
        } else {
          return this.innerValue;
        }
      },
      set(value) {
        this.innerValue = value;
      },
    },
    getMaxDate() {
      // TODO:スクロール位置がズレる(0になる)ので調整できればこの設定は不要
      // 5年先まで表示
      return dayjs().add(5, 'y').format().substr(0, 10);
    }, 
  },
};
</script>

<style scoped>
/** テーブルに入れ込む時のスタイル */
.td-data-picker.v-input >>> .v-input__slot {
    margin-top: 12px;
}
</style>