import Axios from "axios";
import token from "./setToken";

export default {
  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MailTemplateList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メールテンプレートを取得できません[search]");
      throw error;
    }
  },

  async getData(templateId) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/MailTemplate/"+templateId, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メールテンプレートを取得できません");
      throw error;
    }
  },

  async getMailTemplateDataForType(type) {
    try {
      // await token.checkToken();
      const res = await Axios.get(`/api/MailTemplateType/${type}`, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メールテンプレートを取得できません[type]");
      throw error;
    }
  },

  async updateData(templateData, mailDestArray, mode) {
    let params = new URLSearchParams();
    params.append('templateData', JSON.stringify(templateData));
    params.append('mailDestArray', JSON.stringify(mailDestArray));
    params.append('mode', mode);
    
    try {
      // await token.checkToken();
      const res = await Axios.post(
        "/api/MailTemplate", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("メールテンプレートを設定できません[updateData]");
      throw error;
    }
  },

  async deleteData(templateId) {
    try {
      // await token.checkToken();
      const res = await Axios.delete('/api/MailTemplate/' + templateId, token.setTokenToHeader());
      return res;

    } catch (error) {
      // エラーログを出力して、再スロー
      console.error("データを削除できません[deleteData]");
      throw error;
    }
  }, 
};
