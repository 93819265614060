import Axios from "axios";
import token from "./setToken";

export default {
  async setFactFindingData(mailData, mailDestArray, factFindingData, attachment, mode) {

    //let params = new URLSearchParams();
    let params = new FormData();
    params.append('mailData', JSON.stringify(mailData));
    params.append('mailDestArray', JSON.stringify(mailDestArray));
    params.append('factFindingData', JSON.stringify(factFindingData));
    // ファイル
    for(let i=0; i < attachment.length; i++) {
      params.append('attachment['+i+']', attachment[i]);
    }
    params.append('mode', mode);

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/FactFinding', params, token.setTokenToHeaderFile());
      return res;

    } catch (error) {
      console.error("実態調査を設定できません");
      throw error;
    }
  }, 

  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査を取得できません[search]");
      throw error;
    }
  },

  async getSurveyAnsStatus(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingAnsStatus", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査を取得できません[getSurveyAnsStatus]");
      throw error;
    }
  },

  /** 調査取得 */
  async getExistSurvey(targetYear) {
    let params = new URLSearchParams();
    params.append('targetYear', JSON.stringify(targetYear));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingExistSurvey", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査を取得できません[getExistSurvey]");
      throw error;
    }
  },

  /** 集計 */
  async summary(targetYear, factFindingAnswerDataList) {
    let params = new URLSearchParams();
    params.append('targetYear', JSON.stringify(targetYear));
    params.append('factFindingAnswerDataList', JSON.stringify(factFindingAnswerDataList));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingSum", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査データを集計できません[summary]");
      throw error;
    }
  },

  /** 集計 基本情報取得 */
  async getSumOverview(targetYear) {
    let params = new URLSearchParams();
    params.append('targetYear', JSON.stringify(targetYear));

    try {
      // await token.checkToken();
      const res = await Axios.post("/api/FactFindingSumOverview", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("実態調査データを取得できません[getSumOverview]");
      throw error;
    }
  },

  /** 個別返答アップロード */
  async uploadReplyFile(indivFiles, tlFiles) {
    let params = new FormData();
    // 個別返答ファイル
    for(let i=0; i < indivFiles.length; i++) {
      params.append('indivFiles['+i+']', indivFiles[i]);
    }
    // 時系列ファイル
    for(let i=0; i < tlFiles.length; i++) {
      params.append('tlFiles['+i+']', tlFiles[i]);
    }

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/FactFindingUploadReplyFile', params, token.setTokenToHeaderFile());
      return res;

    } catch (error) {
      console.error("実態調査 個別返答ファイルを設定できません");
      throw error;
    }
  }, 

  /** 個別返答メール送信 */
  async sendReply(mailData, mailDestArray, sendData, attachment) {
    let params = new FormData();
    params.append('mailData', JSON.stringify(mailData));
    params.append('mailDestArray', JSON.stringify(mailDestArray));
    params.append('sendData', JSON.stringify(sendData));
    // ファイル
    for(let i=0; i < attachment.length; i++) {
      params.append('attachment['+i+']', attachment[i]);
    }

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/FactFindingSendReply', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("個別返答メールを送信できません");
      throw error;
    }
  },
};
