import Axios from "axios";
import token from "./setToken";

export default {

  /**
   * 事業所会員データリストを取得
   * @param {*} searchCondition 
   * @returns 
   */
  async search(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
 
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/MemberList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("事業所会員を取得できません[search]");
      throw error;
    }
  },

  /**
   * 選択事業所会員データリストを取得
   * @param {*} searchCondition 
   * @returns 
   */
   async selectMemberList(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
 
    try {
      // await token.checkToken();
      const res = await Axios.post("/api/SelectMemberList", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("事業所会員を取得できません[selectMemberList]");
      throw error;
    }
  },

  /**
   * 
   * @param {*} memberNo 
   * @returns 
   */
  async getData(memberNo) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/Member/" + memberNo, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("会員情報を取得できません[getData]");
      throw error;
    }
  },


  /**
   * 
   * @returns 
   */  
  // async getAllData() {
  //   try {
  //     await token.checkToken();
  //     const res = await Axios.get("/api/Member", token.setTokenToHeader());
  //     return res;
  //   } catch (error) {
  //     console.error("会員一覧を取得できません[getAllData]");
  //     throw error;
  //   }
  // },

  /**
   * データ更新
   */
  async setData(memberData, mode, officeMemberList) {
    let params = new URLSearchParams();
    const modeJp = (mode == "delete" ? "削除" : "保存"); // 表示する実行内容をmodeによって場合分け
    params.append('memberData', JSON.stringify(memberData));
    params.append('officeMemberList', JSON.stringify(officeMemberList));
    params.append('mode', mode);

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/Member', params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("会員情報を" + modeJp + "できません[setData]");
      throw error;
    }
  },

  /**
   * 新規事業所ID を取得
   */
  async getNewNo(prefId) {
    try {
      const res = await Axios.get('/api/MemberNo/' + ('00' + prefId).slice(-2), token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("会員情報を取得できません[getNewNo]");
      throw error;
    }
  },


  //---------------------------------------------------------------------------
  async getHistoryData(searchCondition) {
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));
 
    try {
      const res = await Axios.post("/api/Member/updateHistory", params, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("増減明細を取得できません[getHistoryData]");
      throw error;
    }
  },

};
